* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body ::selection {
  background-color: #fff3;
}

a, a:visited {
  color: inherit;
}

a:hover {
  opacity: .5;
}

html, body {
  color: #c8c8c8;
  background: #000;
  height: 100%;
}

main {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

h1 {
  word-spacing: 2em;
  margin-bottom: .5625rem;
  font-size: .875rem;
  font-weight: normal;
  line-height: 1;
}

#info {
  text-transform: uppercase;
  cursor: default;
  padding: 5vmin;
  line-height: 1.33333;
}

#info__primary, #info__secondary {
  font-size: .5625rem;
}

#info > div p:not(:last-of-type) {
  margin-bottom: 1.33333em;
}

#info__primary {
  gap: 4rem;
  margin-bottom: 1em;
  display: flex;
}

#info__primary ul {
  gap: .5em;
  list-style-type: none;
  display: flex;
}

#info__primary ul li a {
  text-decoration: none;
}

#info__primary ul li a:after {
  content: ".";
}

#media {
  opacity: 0;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  transition: opacity .75s;
  display: flex;
}

#images__container {
  aspect-ratio: 16 / 9;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  flex: none;
  width: max(5vmin, min(67.5vmin, 50%));
  position: relative;
}

#images__container img {
  object-fit: contain;
  opacity: 0;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

video {
  aspect-ratio: 9 / 16;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  flex: none;
  width: min(37.9688vmin, 28.125%);
}

@media screen and (orientation: portrait) {
  #media {
    flex-direction: column-reverse;
    transform: translateY(2.5vmin);
  }

  #images__container {
    width: min(67.5vmin, 45%);
  }

  video {
    width: min(37.9688vmin, 25.3125%);
  }
}

@media screen and (max-aspect-ratio: 1 / 1.5) {
  #images__container {
    width: 67.5vmin;
  }

  video {
    width: 37.9688vmin;
  }
}

@media screen and (orientation: landscape) {
  #media {
    transform: translateY(calc(2.5vmin + 1.4375rem));
  }
}

@media screen and (max-width: 450px) {
  html, body {
    color: #fff;
  }

  main {
    flex-direction: column-reverse;
  }

  #media {
    transform: translateY(-2.5vmin);
  }

  #info {
    padding-bottom: 0;
  }

  #info__primary {
    margin-bottom: 0;
  }

  #info__secondary {
    display: none;
  }

  #images__container {
    flex: initial;
    width: 77.5vmin;
  }

  video {
    flex: initial;
    width: 43.5938vmin;
  }
}

@media screen and (min-width: 1919px) {
  html, body {
    font-size: 18px;
  }
}

/*# sourceMappingURL=index.aef5129f.css.map */
