* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  ::selection {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

a,
a:visited {
  color: inherit;
}

a:hover {
  opacity: 0.5;
}

html,
body {
  height: 100%;
  background: #000;
  color: #c8c8c8;
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: normal;
  margin-bottom: 0.5625rem;
  word-spacing: 2em;
}

#info {
  line-height: 1.333333;
  text-transform: uppercase;
  cursor: default;
  padding: 5vmin;
}

#info__primary,
#info__secondary {
  font-size: 0.5625rem;
}

#info > div p:not(:last-of-type) {
  margin-bottom: 1.333333em;
}

#info__primary {
  display: flex;
  gap: 4rem;
  margin-bottom: 1em;
}

#info__primary ul {
  list-style-type: none;
  display: flex;
  gap: 0.5em;
}

#info__primary ul li a {
  text-decoration: none;
}

#info__primary ul li a::after {
  content: ".";
}

#media {
  display: flex;
  opacity: 0;
  transition: opacity 750ms ease;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

#images__container {
  width: max(5vmin, min(67.5vmin, 50%));
  position: relative;
  aspect-ratio: 16/9;
  flex: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

#images__container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  pointer-events: none;
}

video {
  flex: none;
  aspect-ratio: 9 / 16;
  width: min(calc(67.5vmin * 0.5625), calc(50% * 0.5625));
  -webkit-user-drag: none;
  -webkit-user-select: none;
}

@media screen and (orientation: portrait) {
  #media {
    flex-direction: column-reverse;
    transform: translateY(2.5vmin);
  }
  #images__container {
    width: min(67.5vmin, 45%);
  }
  video {
    width: min(calc(67.5vmin * 0.5625), calc(45% * 0.5625));
  }
}

@media screen and (max-aspect-ratio: 1/1.5) {
  #images__container {
    width: 67.5vmin;
  }
  video {
    width: calc(67.5vmin * 0.5625);
  }
}

@media screen and (orientation: landscape) {
  #media {
    transform: translateY(calc(2.5vmin + 0.875rem + 0.5625rem));
  }
}

@media screen and (max-width: 450px) {
  html,
  body {
    color: #fff;
  }
  main {
    flex-direction: column-reverse;
  }
  #media {
    transform: translateY(-2.5vmin);
  }
  #info {
    padding-bottom: 0;
  }
  #info__primary {
    margin-bottom: 0;
  }
  #info__secondary {
    display: none;
  }
  #images__container {
    width: 77.5vmin;
    flex: initial;
  }
  video {
    width: calc(77.5vmin * 0.5625);
    flex: initial;
  }
}

@media screen and (min-width: 1919px) {
  html,
  body {
    font-size: 18px;
  }
}
